import { handleActions } from 'redux-actions';
import Actions from '../actions/Actions';

const defaultState = {
  showMenuPanel: false,
  showDisclaimer: false
};

export default handleActions({

  // action.payload = show
  [Actions.SHOW_MENU_PANEL]: (state, action) => {
    return {
      ...state,
      showMenuPanel: action.payload
    }
  },

  // action.payload = show
  [Actions.SHOW_DISCLAIMER_MODAL]: (state, action) => {
    return {
      ...state,
      showDisclaimer: action.payload
    }
  }
}, defaultState);
