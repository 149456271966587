import Colors from "../../../enums/Colors";

const styles = ({

    productsGrid: {
        position: 'relative',
        height: 'auto',
    },

    preblock: {
        backgroundColor: Colors.WHITE,
        opacity: 0.9,
        height: '12vw'
    },

    heading: {
        display: 'flex',
        overflow: 'hidden'
    },


    splitter: {
        backgroundColor: Colors.WHITE,
        opacity: 0.9,
        height: '5vh'
    },

    hleft: {
        backgroundColor: Colors.WHITE,
        opacity: 0.9,
        flexGrow: 1
    },

    hright: {
        fontFamily: 'Gibson',
        fontSize: '7vw',
        color: Colors.WHITE,
        opacity: 0.9,
        marginLeft: '2vw',
        marginRight: '2vw',
    },

    products: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },

    product: {
        position: 'relative',
        height: 'auto',
        width: '30vw',
        minWidth: '300px',
        backgroundColor: Colors.WHITE,
        opacity: 0.9,
        margin: '3vh',
        boxSizing: "border-box",
        marginTop: '20vw'
    },

    productsLeft: {
        '& > div:first-child': {
            marginTop: '5vh'
        }
    },

    productsRight: {
        marginBottom: '20vh',
        '& > div:first-child': {
            marginTop: '40vw'
        }
    }

});

export default styles;