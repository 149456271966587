import Colors from "../../../enums/Colors";
import ContactImage from '../../../assets/images/contact.jpg';

const styles = ({

    contactGrid: {
        position: 'relative',
        height: 'auto',
    },

    preblock: {
        backgroundColor: Colors.WHITE,
        opacity: 0.9,
        height: '12vw'
    },

    splitter: {
        backgroundColor: Colors.WHITE,
        opacity: 0.9,
        height: '5vh'
    },

    heading: {
        display: 'flex',
        overflow: 'hidden'
    },

    hleft: {
        backgroundColor: Colors.WHITE,
        opacity: 0.9,
        flexGrow: 1
    },

    hmiddle: {
        fontFamily: 'Gibson',
        fontSize: '7vw',
        color: Colors.GREEN,
        opacity: 0.9,
        marginLeft: '2vw',
        marginRight: '2vw',
    },

    hright: {
        backgroundColor: Colors.WHITE,
        opacity: 0.9,
        flexGrow: 1
    },

    content: {
        marginTop: '20vh',
        position: 'relative'
    },

    centerWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },

    fourSplit: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 300,
        width: '40vw',
        justifyContent: 'center',
        backgroundColor: Colors.WHITE,
        opacity: 0.9,
    },

    sloganWrapper: {
        backgroundColor: Colors.WHITE,
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },

    slogan: {
        padding: '1.5vw 11vw',
        paddingRight: '30vw',
        fontSize: "calc(14px + 0.4vw)",
        maxWidth: 300,
        color: Colors.GREY_1
    },

    element: {
        width: '50%',
        minWidth: '300px',
        boxSizing: 'border-box',
        padding: '1.5vw'
    },

    title: {
        color: Colors.GREEN,
        fontSize: "calc(12px + 0.2vw)",
        fontWeight: 'bold',
        marginBottom: 15
    },

    text: {
        color: Colors.GREY_2,
        fontSize: "calc(12px + 0.4vw)"
    },

    image: {
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        width: '30vw',
        background: `url(${ContactImage}) no-repeat center center fixed`,
        backgroundSize: 'cover',
        opacity: 0.9
    }

});

export default styles;