import React, {useEffect} from "react";
import PropTypes from "prop-types";

import {connect} from 'react-redux';
import {animated} from 'react-spring';
import {createUseStyles} from "react-jss";
import styles from "./Contact.jss";

const useStyles = createUseStyles(styles, {name: 'Contact'});

const propTypes = {
    contactRef: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    data: state.data.contacts
});

const mapDispatchToProps = {};

let contactOffset = 0;
let contactsRef;
const calcHeader = o => `${o && (280 - contactsRef.current.getBoundingClientRect().top) * 0.2}vw`;

function Contact({data, offset, contactRef}) {
    const classes = useStyles();

    useEffect(() => {
        if (contactRef.current)
            contactOffset = contactRef.current.offsetTop;
        contactsRef = contactRef;
    });

    return (
        <div ref={contactRef} className={classes.contactGrid}>
            <div className={classes.preblock}/>
            <div className={classes.heading}>
                <div className={classes.hleft}/>
                <animated.div className={classes.hmiddle} style={{marginLeft: offset.interpolate(calcHeader)}}>
                    KONTAKT
                </animated.div>
                <div className={classes.hright}/>
            </div>
            <div className={classes.splitter}/>
            <div className={classes.content}>
                <div className={classes.sloganWrapper}>
                    <div className={classes.slogan}>
                        Ob Anfrage, oder nur um Hallo zu sagen, treten sie mit uns in kontakt.
                    </div>
                </div>
                <div className={classes.centerWrapper}>
                    <div className={classes.fourSplit}>
                        <div className={classes.element}>
                            <div className={classes.title}>Phone</div>
                            <div className={classes.text}>{data.phone}</div>
                        </div>
                        <div className={classes.element}>
                            <div className={classes.title}>Anfragen</div>
                            <div className={classes.text}>{data.mail}</div>
                        </div>
                        <div className={classes.element}>
                            <div className={classes.title}>Adresse</div>
                            <div className={classes.text}>{data.street}</div>
                            <div className={classes.text}>{data.city}</div>
                        </div>
                        <div className={classes.element}>
                            <div className={classes.title}>Ihre</div>
                            <div className={classes.text}>{data.name}</div>
                        </div>
                    </div>
                </div>
                <animated.div className={classes.image} style={{}}/>
            </div>
        </div>
    )
}

Contact.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(Contact);