import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";

import {connect} from 'react-redux';
import {animated} from 'react-spring';
import {createUseStyles} from "react-jss";
import styles from "./Products.jss";
import Colors from "../../../enums/Colors";
import Product from "./Product/Product";

import Aussenputz from '../../../assets/images/pics/aussenputz.jpg';
import Innenputz from '../../../assets/images/pics/innenputz.jpg';
import Bodensysteme from '../../../assets/images/pics/bodensysteme.jpg';
import WDVSysteme from '../../../assets/images/pics/wdvs.jpg';
import Malen from '../../../assets/images/pics/malen.jpg';
import Stuck from '../../../assets/images/pics/stuck.jpg';
import Feuchtesanierung from '../../../assets/images/pics/feuchtesanierung.jpg';
import Altbausanierung from '../../../assets/images/pics/altbau.jpg';
import Trockenbau from '../../../assets/images/pics/trockenbau.jpg';
import Geruestbau from '../../../assets/images/pics/geruestbau.jpg';

const useStyles = createUseStyles(styles, {name: 'Products'});

const propTypes = {
    productsRef: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    data: state.data.products
});

const mapDispatchToProps = {};

let productOffset = 0;
let productRef;
// const calcHeader = o => `${(o + 100 - productOffset) * 0.4}vw`;
// const calcShadow = o => `2px ${-60 + (o - productOffset)}px ${Colors.ORANGE}`;
const calcShadow = o => `2px ${o && (0 + (window.innerHeight/8) - productRef.current.getBoundingClientRect().top) * 0.2}px 1vw ${Colors.ORANGE}`;
const calcHeader = o => `${o && (0 + (window.innerHeight/6) - productRef.current.getBoundingClientRect().top) * 0.2}vw`;


let refProd1, refProd2, refProd3, refProd4, refProd5, refProd6, refProd7, refProd8, refProd9, refProd10;
const calcProd1 = o => `${o && ((0 + (window.innerHeight/1.5)) - refProd1.current.getBoundingClientRect().top) * 0.003}`;
const calcProd2 = o => `${o && ((0 + (window.innerHeight/1.5)) - refProd2.current.getBoundingClientRect().top) * 0.003}`;
const calcProd3 = o => `${o && ((0 + (window.innerHeight/1.5)) - refProd3.current.getBoundingClientRect().top) * 0.003}`;
const calcProd4 = o => `${o && ((0 + (window.innerHeight/1.5)) - refProd4.current.getBoundingClientRect().top) * 0.003}`;
const calcProd5 = o => `${o && ((0 + (window.innerHeight/1.5)) - refProd5.current.getBoundingClientRect().top) * 0.003}`;
const calcProd6 = o => `${o && ((0 + (window.innerHeight/1.5)) - refProd6.current.getBoundingClientRect().top) * 0.003}`;
const calcProd7 = o => `${o && ((0 + (window.innerHeight/1.5)) - refProd7.current.getBoundingClientRect().top) * 0.003}`;
const calcProd8 = o => `${o && ((0 + (window.innerHeight/1.5)) - refProd8.current.getBoundingClientRect().top) * 0.003}`;
const calcProd9 = o => `${o && ((0 + (window.innerHeight/1.5)) - refProd9.current.getBoundingClientRect().top) * 0.003}`;
const calcProd10 = o => `${o && ((0 + (window.innerHeight/1.5)) - refProd10.current.getBoundingClientRect().top) * 0.003}`;

function Products({data, offset, productsRef}) {
    const classes = useStyles();
    refProd1 = useRef();
    refProd2 = useRef();
    refProd3 = useRef();
    refProd4 = useRef();
    refProd5 = useRef();
    refProd6 = useRef();
    refProd7 = useRef();
    refProd8 = useRef();
    refProd9 = useRef();
    refProd10 = useRef();

    useEffect(() => {
        if (productsRef.current)
            productRef = productsRef;
            productOffset = productsRef.current.offsetTop;
    });

    return (
        <div ref={productsRef} className={classes.productsGrid}>
            <div className={classes.preblock}/>
            <div className={classes.heading}>
                <div className={classes.hleft}/>
                <animated.div className={classes.hright} style={
                    {
                        marginRight: offset.interpolate(calcHeader),
                        textShadow: offset.interpolate(calcShadow)
                    }
                }>LEISTUNGEN
                </animated.div>
            </div>
            <div className={classes.splitter}/>

            <div className={classes.products}>
                <div className={classes.productsLeft}>
                    <animated.div ref={refProd1} className={classes.product} style={{opacity: offset.interpolate(calcProd1)}}>
                        <Product data={data.product1} image={Aussenputz}/>
                    </animated.div>
                    <animated.div ref={refProd3} className={classes.product} style={{opacity: offset.interpolate(calcProd3)}}>
                        <Product data={data.product3} image={Bodensysteme}/>
                    </animated.div>
                    <animated.div ref={refProd5} className={classes.product} style={{opacity: offset.interpolate(calcProd5)}}>
                        <Product data={data.product5} image={Malen}/>
                    </animated.div>
                    <animated.div ref={refProd7} className={classes.product} style={{opacity: offset.interpolate(calcProd7)}}>
                        <Product data={data.product7} image={Feuchtesanierung}/>
                    </animated.div>
                    <animated.div ref={refProd9} className={classes.product} style={{opacity: offset.interpolate(calcProd9)}}>
                        <Product data={data.product9} image={Trockenbau}/>
                    </animated.div>
                </div>
                <div className={classes.productsRight}>
                    <animated.div ref={refProd2} className={classes.product} style={{opacity: offset.interpolate(calcProd2)}}>
                        <Product data={data.product2} image={Innenputz}/>
                    </animated.div>
                    <animated.div ref={refProd4} className={classes.product} style={{opacity: offset.interpolate(calcProd4)}}>
                        <Product data={data.product4} image={WDVSysteme}/>
                    </animated.div>
                    <animated.div ref={refProd6} className={classes.product} style={{opacity: offset.interpolate(calcProd6)}}>
                        <Product data={data.product6} image={Stuck}/>
                    </animated.div>
                    <animated.div ref={refProd8} className={classes.product} style={{opacity: offset.interpolate(calcProd8)}}>
                        <Product data={data.product8} image={Altbausanierung}/>
                    </animated.div>
                    <animated.div ref={refProd10} className={classes.product} style={{opacity: offset.interpolate(calcProd10)}}>
                        <Product data={data.product10} image={Geruestbau}/>
                    </animated.div>
                </div>
            </div>
        </div>
    )
}

Products.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(Products);