import React from 'react';

import { Provider } from 'react-redux';
import AppStore from './state/stores/AppStore';

import styles from './App.jss';
import Router from './routing/Router';
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(styles, { name: 'App' });

function App() {
  const classes = useStyles();

  return (

      <Provider store={AppStore}>
        <div className={classes.app}>
          <Router />
        </div>
      </Provider>
  );
}

export default App;
