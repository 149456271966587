import React, {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import classNames from "classnames";

import {createUseStyles} from "react-jss";
import styles from "./Menu.jss";
import {useResize} from "../../hooks/useResize";
import MenuImage from '../../assets/images/menu_icon.svg';

const useStyles = createUseStyles(styles, {name: 'Menu'});

function Menu({showBg, handleClick}) {
    const classes = useStyles();

    const [menuOpen, setMenuOpen] = useState(false);
    const width = useResize();
    const smallVersion = width < 1200;

    const menuClasses = classNames({
        [classes.menu]: true,
        [classes.menuBg]: showBg
    });

    const smallMenuClasses = classNames({
        [classes.rightMenuEntriesSmall]: true,
        [classes.open]: menuOpen
    })

    return (
        <div className={menuClasses}>
            <div className={classes.leftMenuEntries}>
                <div id={"welcome"} onClick={handleClick} className={classNames({[classes.menuItem]: true, [classes.leftMenuItem]: true})}>Weber Projektgestaltung GmbH
                </div>
            </div>
            {
                smallVersion &&
                <div className={classes.rightMenuSmall} onClick={() => setMenuOpen(!menuOpen)}>
                    <div className={classes.menuButton}>
                        <img src={MenuImage} className={classes.menuButtonImage}/>
                    </div>
                    <div className={smallMenuClasses}>
                        <div id={"company"} onClick={handleClick}
                             className={classNames({
                                 [classes.menuItem]: true,
                                 [classes.company]: true,
                                 [classes.menuItemBg]: showBg
                             })}>Unternehmen
                        </div>
                        <div id={"products"} onClick={handleClick}
                             className={classNames({
                                 [classes.menuItem]: true,
                                 [classes.products]: true,
                                 [classes.menuItemBg]: showBg
                             })}>Leistungen
                        </div>
                        <div id={"contact"} onClick={handleClick}
                             className={classNames({
                                 [classes.menuItem]: true,
                                 [classes.contact]: true,
                                 [classes.menuItemBg]: showBg
                             })}>Kontakt
                        </div>
                        <div id={"disclaimer"} onClick={handleClick}
                             className={classNames({
                                 [classes.menuItem]: true,
                                 [classes.impressum]: true,
                                 [classes.menuItemBg]: showBg
                             })}>Impressum
                        </div>
                    </div>
                </div>
            }
            {
                !smallVersion &&
                <div className={classes.rightMenu}>
                    <div className={classes.rightMenuEntries}>
                        <div id={"company"} onClick={handleClick}
                             className={classNames({[classes.menuItem]: true, [classes.company]: true})}>Unternehmen
                        </div>
                        <div id={"products"} onClick={handleClick}
                             className={classNames({[classes.menuItem]: true, [classes.products]: true})}>Leistungen
                        </div>
                        <div id={"contact"} onClick={handleClick}
                             className={classNames({[classes.menuItem]: true, [classes.contact]: true})}>Kontakt
                        </div>
                        <div id={"disclaimer"} onClick={handleClick}
                             className={classNames({[classes.menuItem]: true, [classes.impressum]: true})}>Impressum
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default Menu;