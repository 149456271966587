import Colors from "../../enums/Colors";

const styles = ({

    scrollDown: {
        position: 'fixed',
        bottom: 10,
        left: 'calc(50% - 25px)',
        width: '50px',
        height: '85px',
        borderRadius: '35px',
        border: '1px solid white',
    },

    circle: {
        width: 14,
        height: 14,
        borderRadius: '50%',
        background: Colors.WHITE,
        position: 'absolute',
        left: 18,
        top: 10
    }
});

export default styles;