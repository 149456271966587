import Colors from "../../../enums/Colors";

const styles = ({

    welcomeGrid: {
        position: 'relative',
        height: '100vh',
        // backgroundColor: Colors.BLUE,
        // opacity: 0.2
    },

    willkommen: {
        fontSize: '2rem',
        position: 'relative',
        left: '30vw',
        width: '70vw',
        paddingTop: '50vh'
    },

    weberHighlight: {
        color: Colors.BLUE,
        fontWeight: 'bold'
    },

    slogan: {
        fontSize: '2rem',
        position: 'relative',
        left: '30vw',
        top: 110,
        color: Colors.WHITE
    },

    logo: {
        position: 'absolute',
        right: '60%',
        height: '10rem',
        top: 0
    },

    logoInner: {

    }

});

export default styles;