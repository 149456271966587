import Colors from './enums/Colors';

import gibsonBold from './assets/fonts/gibson-bold.eot';
import gibsonBoldTtf from './assets/fonts/gibson-bold.ttf';

const styles = ({

    '@font-face': [
        {
            fontFamily: '"Gibson"',
            fallbacks: [
                {src: `url(${gibsonBold})`},
                {
                    src: `url(${gibsonBoldTtf}) format("truetype")`
                }
            ],
            fontStyle: 'normal',
            fontWeight: 'normal'
        },
    ],

    '@global': {
        body: {
            fontFamily: 'sans-serif',
            margin: '0',
            padding: '0',

            fontSize: 16,

            color: Colors.BLACK,
            backgroundColor: Colors.WHITE
        },


        'p, ol, ul, li, h1, h2, h3, h4, h5, h6, button': {
            margin: 0,
            padding: 0,
            fontWeight: 'normal'
        },
    },

    app: {
        display: 'flex',
        flexDirection: 'column',

        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        overflow: 'hidden',

        backgroundColor: Colors.BACKGROUND
    }
});

export default styles;