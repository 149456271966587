import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { render } from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

import { ThemeProvider } from 'react-jss';
import { loadPolyfills } from './utils/helpers';

loadPolyfills();

function RootComponent() {
    return (
        <ThemeProvider theme={{}}>
            <App />
        </ThemeProvider>
    );
};

render(<RootComponent />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
