const styles = {
  loading: {
    animation: '$spin .6s infinite',
    animationTimingFunction: 'linear',

    borderLeft: '#f0ab00 .125rem solid',
    borderRight: 'transparent .125rem solid',
    borderTop: '#f0ab00 .125rem solid',

    borderRadius: '50%',

    height: 48,
    width: 48,
  },

  small: {
    width: 24,
    height: 24
  },

  medium: {
    width: 32,
    height: 32
  },

  '@keyframes spin': {
    'from': {
      transform: 'rotate(0)',
    },

    'to': {
      transform: 'rotate(360deg)',
    }
  }
};

export default styles;
