import { handleActions, combineActions } from 'redux-actions';
import Actions from '../actions/Actions';

import Welcome from '../data/welcome.json';
import Company from '../data/company.json';
import Products from '../data/products.json';
import Contacts from '../data/contacts.json';
import Disclaimer from '../data/disclaimer.json';

const defaultState = {
  loading: false,
  error: null,
  appError: null,
  welcome: Welcome,
  company: Company,
  products: Products,
  contacts: Contacts,
  disclaimer: Disclaimer
};

export default handleActions({

  /**
   * ---------------------
   * GENERICS
   * ---------------------
   */

  // action.payload = filterQuery
  [Actions.SET_LOADING]: (state, action) => {
    return {
      ...state,
      loading: true
    };
  },

  // action.payload = filterQuery
  [Actions.UNSET_LOADING]: (state, action) => {
    return {
      ...state,
      loading: false
    };
  },

}, defaultState);