import Pencils from "../../assets/images/pencils.jpg";

const styles = ({

    bg: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: `url(${Pencils}) no-repeat center center fixed`,
        backgroundSize: 'cover'
    },
});

export default styles;