import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import classNames from "classnames";

import { createUseStyles } from "react-jss";
import styles from "./Background.jss";

const useStyles = createUseStyles(styles, { name: 'Background' });

function Background({}) {
    const classes = useStyles();

    return (
        <div className={classes.bg}/>
    )
}

export default Background;