import React from "react";
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";

import { createUseStyles } from "react-jss";
import styles from "./Product.jss";

const useStyles = createUseStyles(styles, { name: 'Product' });

const propTypes = {
    companyRef: PropTypes.object.isRequired,
};

function Product({data, image}) {
    const classes = useStyles();

    return (
        <div className={classes.product}>
            <div className={classes.title}>{data.title}</div>
            <div className={classes.text}>{data.section1}</div>
            {data.section2 && <div className={classes.text2}>{data.section2}</div>}
            {data.section3 && <div className={classes.text2}>{data.section3}</div>}
            <div className={classes.products}>
                <ul className={classes.list}>
                    {data.products.map((value, index) => {
                        return <li className={classes.item} key={index}>{value}</li>
                    })}
                </ul>
            </div>
            <div className={classes.image}>
                <img src={image} alt={data.title} width={"100%"} className={classes.imageInner}/>
            </div>
        </div>
    )
}

Product.propTypes = propTypes;
export default Product;