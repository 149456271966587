import Colors from "../../../enums/Colors";

const styles = ({

    disclaimerGrid: {
        position: 'relative',
        height: 'auto'
    },

    preblock: {
        backgroundColor: Colors.BLACK,
        opacity: 0.9,
        height: '15vw'
    },

    splitter: {
        backgroundColor: Colors.BLACK,
        opacity: 0.9,
        height: '5vh'
    },

    disclaimerBlock: {
        display: 'flex',
    },

    emptyDiv: {
        flexGrow: 1,
        backgroundColor: Colors.BLACK,
        opacity: 0.9
    },

    disclaimerSvg: {
        padding: '1vw',
        width: '60vw'
    },

    content: {
        // height:'2000px',
        backgroundColor: Colors.BLACK,
        opacity: 0.9,
        color: Colors.WHITE,
        padding: 30,
        paddingLeft: '10%',
        paddingRight: '10%',
    },

    title: {
        paddingTop: 50,
        paddingBottom: 15,
        color: Colors.BLUE,
        fontWeight: 'bold'
    },

    sTitle: {
        paddingTop: 15,
        paddingBottom: 5,
        color: Colors.BLUE,
    },

    text: {

    },

    source: {
        paddingTop: 40
    },

    link: {
        color: Colors.BLUE,
        opacity: 0.7
    }
});

export default styles;