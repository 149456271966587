import React, {Suspense, useRef, useEffect, useState} from 'react';

import {HashRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import Menu from '../components/Menu/Menu';
import Content from '../components/Content/Content';
import Loading from '../components/Loading/Loading';
import Background from "../components/Background/Background";
import ScrollDown from "../components/ScrollDown/ScrollDown";

const mapStateToProps = state => ({
    loading: state.data.loading
});

const mapDispatchToProps = {};

function Router({appError, loading}) {

    const welcomeRef = useRef();
    const companyRef = useRef();
    const productsRef = useRef();
    const contactRef = useRef();
    const disclaimerRef = useRef();
    const contentRef = useRef();
    const scrollRefs = {
        "welcome": welcomeRef,
        "company": companyRef,
        "products": productsRef,
        "contact": contactRef,
        "disclaimer": disclaimerRef
    };
    const [showBg, setShowBg] = useState(false);

    // show loading indicator
    if (loading) {
        return (
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Loading/>
            </div>
        );
    }

    const height = window.innerHeight;
    const width = window.innerWidth;
    const ratio = height / width;

    function handleClick(e) {
        const ref = scrollRefs[e.target.id];
        contentRef.current.scrollTo({
            top: ref.current.offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    }

    function handleScrollPosChange(e){
        if(contentRef.current.scrollTop > window.innerHeight - 100){
            setShowBg(true);
        } else {
            setShowBg(false);
        }
    }

    return (
        <HashRouter>
            <Suspense fallback={<Loading/>}>

                <Menu showBg={showBg} handleClick={handleClick}/>
                <Background/>
                <Content contentRef={contentRef} handleScrollPosChange={handleScrollPosChange} scrollRefs={scrollRefs}/>
                {/*<ScrollDown/>*/}

                {/*<DisclaimerModal />*/}
            </Suspense>
        </HashRouter>
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(Router);
