import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import styles from './Loading.jss';

const propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['S', 'M', 'L'])
};

const useStyles = createUseStyles(styles, { name: 'Loading' });

function Loading({ className, size = 'L' }) {
  const classes = useStyles();

  const loadingClasses = classNames({
    [classes.loading]: true,
    [classes.small]: size === 'S',
    [classes.medium]: size === 'M',
    [classes.large]: size === 'L',
    [className]: !!className
  })

  return (
    <div className={loadingClasses} />
  );
};

Loading.propTypes = propTypes;

export default Loading;
