import Colors from "../../enums/Colors";

const styles = ({

    content: {
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,

        // transition: 'transform 0.3s ease-in-out'
    },

    scrollWrapper: {
        top: 0,
    },

    welcome: {
        height: '100vh',
        position: 'relative'
    },

    company: {
        height: "auto"
    },

    products: {
        height: 'auto'
    },

    contact: {
        height: 'auto'
    },

    disclaimer: {
        height: 'auto'
    }
});

export default styles;