import Colors from "../../../enums/Colors";

const styles = ({

    companyGrid: {
        position: 'relative',
        height: 'auto',
        boxSizing: 'border-box',
        overflow: 'hidden'
    },

    preblock: {
        backgroundColor: Colors.WHITE,
        opacity: 0.9,
        height: '12vw'
    },

    heading: {
        display: 'flex'
    },

    hleft: {
        fontFamily: 'Gibson',
        fontSize: '7vw',
        color: Colors.WHITE,
        opacity: 0.9,
        marginLeft: '2vw',
        marginRight: '2vw',
    },

    hright: {
        backgroundColor: Colors.WHITE,
        opacity: 0.9,
        flexGrow: 1
    },

    splitter: {
        backgroundColor: Colors.WHITE,
        opacity: 0.9,
        height: '5vh'
    },

    profile: {
        backgroundColor: Colors.WHITE,
        opacity: 0.9,
        position: "relative",
        transition: "transform 0.3s ease-out",
        marginLeft: "calc(100vw - 50vw)",
        maxWidth: '40vw',
        minWidth: '250px',
        padding: '3vw',
        boxSizing: "border-box",
        margin: '5vh 0px'
    },

    success: {
        backgroundColor: Colors.WHITE,
        opacity: 0.9,
        position: "relative",
        marginLeft: '20vw',
        maxWidth: '30vw',
        minWidth: '250px',
        padding: '3vw',
        boxSizing: "border-box",
        marginBottom: '15vh'
    },

    title: {
        paddingBottom: '1vw',
        fontWeight: "bold",
        color: Colors.PINK,
        fontSize: 'calc(16px + 1vw)'
    },

    cont: {
        fontSize: 'calc(12px + 0.5vw)',
    },

    between: {
        padding: '10px 0px'
    },

    stripe1: {
        backgroundColor: Colors.PINK,
        opacity: 0.7,
        width: '5vw',
        left: '10vw',
        top: '45.6vh',
        position: 'absolute',
        height: '0vh',
        maxHeight: '120vh'
    },

    stripe2: {
        backgroundColor: Colors.PINK,
        opacity: 0.7,
        width: '5vw',
        left: '100vw',
        top: '70vh',
        position: 'absolute',
        height: '0vh',
        maxHeight: '180vh',
        transform: 'rotate(45deg)',
        transformOrigin: 'top right'
    }
});

export default styles;