import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import { animated } from 'react-spring';
import { createUseStyles } from "react-jss";
import styles from "./Disclaimer.jss";

import DisclaimerImage from '../../../assets/images/disclaimer.svg';

const useStyles = createUseStyles(styles, { name: 'Disclaimer' });

const propTypes = {
    disclaimerRef: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    data: state.data.disclaimer
});

const mapDispatchToProps = {

};

let disclaimerOffset = 0;
let disclaimersRef;
const calcHeader = o => `calc(60vw - ${o && (disclaimersRef.current.getBoundingClientRect().top) * 0.02}vw)`;

function Disclaimer({data, offset, disclaimerRef}) {
    const classes = useStyles();

    useEffect(() => {
        if(disclaimerRef.current)
            disclaimerOffset = disclaimerRef.current.offsetTop;
            disclaimersRef = disclaimerRef;
    });

    return (
        <div ref={disclaimerRef} className={classes.disclaimerGrid}>
            <div className={classes.preblock}/>
            <div className={classes.disclaimerBlock}>
                <div className={classes.emptyDiv}/>
                <animated.div className={classes.disclaimerSvg} style={{width: offset.interpolate(calcHeader)}}>
                    <img src={DisclaimerImage} alt={"Disclaimer"} width={"100%"} className={classes.logoInner}/>
                </animated.div>
                <div className={classes.emptyDiv}/>
            </div>
            <div className={classes.splitter}/>
            <div className={classes.content}>
                <div className={classes.title}>
                    1 | Impressum
                </div>
                <div className={classes.text}>
                    <div className={classes.sTitle}>Firmenname:</div>
                    Weber Projektgestaltung GmbH
                    <div className={classes.sTitle}>Geschäftsführer:</div>
                    Dipl. Wirt. Ing. Bau (FH) Jessica Kirchhoff
                    <div className={classes.sTitle}>Ansprechpartner:</div>
                    Norbert Weber
                    <div className={classes.sTitle}>Sitz:</div>
                    <div>Mozartstraße 16</div>
                    <div>76761 Rülzheim</div>
                    <div>Tel. +49 (0)7272 91 92 71</div>
                    <div>Fax +49 (0)7272 91 92 73</div>
                    <div>E-Mail: info@weber-projekt.com</div>
                    <div className={classes.sTitle}>Amtsgericht:</div>
                    Landau
                    <div className={classes.sTitle}>Umsatzsteueridentnummer:</div>
                    DE 27/465/598/5
                    <div className={classes.sTitle}>Handelsregisternummer:</div>
                    HRB 30878
                    <div className={classes.sTitle}>Gestaltung & Programmierung:</div>
                    Christian Weber, Rheinzabern
                    <div className={classes.sTitle}>(c) Copyright 2012</div>
                    Alle Rechte vorbehalten. Texte, Bilder, Grafiken, Seitenlayout, Quelltext und Animationen dieser Website unterliegen dem Schutz des Urheberrechts. Der Inhalt der Website darf nicht zu kommerziellen Zwecken kopiert, verbreitet oder Dritten zugänglich gemacht werden.
                    <div>
                    Weitere Hinweise unter: <a className={classes.link} href="http://www.disclaimer.de" target="_blank">www.disclaimer.de</a></div>
                </div>
                <div className={classes.title}>
                    2 | Datenschutz
                </div>
                <div className={classes.text}>
                    Durch den Besuch der Website des Anbieters können Informationen über den Zugriff (Datum, Uhrzeit, betrachtete Seite) gespeichert werden. Diese Daten gehören nicht zu den personenbezogenen Daten, sondern sind anonymisiert. Sie werden ausschließlich zu statistischen Zwecken ausgewertet. Eine Weitergabe an Dritte, zu kommerziellen oder nichtkommerziellen Zwecken, findet nicht statt.
                    Der Anbieter weist ausdrücklich darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen und nicht lückenlos vor dem Zugriff durch Dritte geschützt werden kann.
                    Die Verwendung der Kontaktdaten des Impressums zur gewerblichen Werbung ist ausdrücklich nicht erwünscht, es sei denn der Anbieter hatte zuvor seine schriftliche Einwilligung erteilt oder es besteht bereits eine Geschäftsbeziehung. Der Anbieter und alle auf dieser Website genannten Personen widersprechen hiermit jeder kommerziellen Verwendung und Weitergabe ihrer Daten.
                </div>
                <div className={classes.title}>
                    3 | Besondere Nutzungsbedingungen
                </div>
                <div className={classes.text}>
                    Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von der vorgenannten Nummer 2 abweichen, wird an entsprechender Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall die besonderen Nutzungsbedingungen.
                </div>
                <div className={classes.source}>
                    Quelle: <a className={classes.link} href="http://www.juraforum.de" target="_blank">Juraforum</a> | <a className={classes.link} href="http://www.juraforum.de/disclaimer_muster" target="_blank">Disclaimer</a>
                </div>
            </div>
        </div>
    )
}

Disclaimer.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(Disclaimer);