import React, {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import { animated, useSpring, interpolate } from 'react-spring';

import {createUseStyles} from "react-jss";
import styles from "./Content.jss";

import Welcome from "./Welcome/Welcome";
import Company from "./Company/Company";
import Products from "./Products/Products";
import Contact from "./Contact/Contact";
import Disclaimer from "./Disclaimer/Disclaimer";

const useStyles = createUseStyles(styles, {name: 'Content'});

function Content({contentRef, scrollRefs, handleScrollPosChange}) {
    const classes = useStyles();
    const [{ offset }, set] = useSpring(() => ({ offset: 0 }));

    const handleScroll = () => {
        const posY = contentRef.current.firstChild.getBoundingClientRect().top;
        const offset = window.pageYOffset - posY;
        set({ offset });
        handleScrollPosChange();
    };

    useEffect(() => {
        if(contentRef.current)
            contentRef.current.addEventListener("scroll", handleScroll);

        return () => {
            contentRef.current.removeEventListener("scroll", handleScroll);
        };
    });

    return (
        <div ref={contentRef} className={classes.content} id={"content"}>
            <div className={classes.scrollWrapper}>
                <div className={classes.welcome}>
                    <Welcome welcomeRef={scrollRefs.welcome} offset={offset}/>
                </div>
                <div className={classes.company}>
                    <Company companyRef={scrollRefs.company} offset={offset}/>
                </div>
                <div className={classes.products}>
                    <Products productsRef={scrollRefs.products} offset={offset}/>
                </div>
                <div className={classes.contact}>
                    <Contact contactRef={scrollRefs.contact} offset={offset}/>
                </div>
                <div className={classes.disclaimer}>
                    <Disclaimer disclaimerRef={scrollRefs.disclaimer} offset={offset}/>
                </div>
            </div>
        </div>
    )
}

export default Content;

