import React from "react";
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import { createUseStyles } from "react-jss";
import { animated } from 'react-spring';
import styles from "./Welcome.jss";

import Weber from '../../../assets/images/weber.svg';

const useStyles = createUseStyles(styles, { name: 'Welcome' });

const propTypes = {
    companyRef: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    data: state.data.welcome
});

const mapDispatchToProps = {

};

const calc = o => `calc(50vh - ${o * 0.5}px)`;

function Welcome({welcomeRef, offset}) {
    const classes = useStyles();

    return (
        <div ref={welcomeRef} className={classes.welcomeGrid}>
            {/*<div className={classes.logo}>*/}
                {/*<img src={Logo} alt={"Logo"} height={"100%"} className={classes.logoInner}/>*/}
            {/*</div>*/}
            <animated.div className={classes.willkommen} style={{paddingTop: offset.interpolate(calc)}}>
                <img src={Weber} alt={"Weber"} width={"100%"} className={classes.logoInner}/>
            </animated.div>
            {/*<div className={classes.slogan}>*/}
                {/*Wo kreative Ideen umgesetzt werden!*/}
            {/*</div>*/}
        </div>
    )
}

Welcome.propTypes = propTypes;
export default connect(mapStateToProps,mapDispatchToProps)(Welcome);