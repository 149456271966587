import React, {Suspense, useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import classNames from "classnames";

import { createUseStyles } from "react-jss";
import styles from "./ScrollDown.jss";

const useStyles = createUseStyles(styles, { name: 'ScrollDown' });

function ScrollDown({}) {
    const classes = useStyles();

    return (
        <div className={classes.scrollDown}>
            <div className={classes.circle}/>
        </div>
    )
}

export default ScrollDown;