import React from "react";
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import { animated } from 'react-spring';
import { createUseStyles } from "react-jss";
import styles from "./Company.jss";

const useStyles = createUseStyles(styles, { name: 'Company' });

const propTypes = {
    companyRef: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    data: state.data.company
});

const mapDispatchToProps = {

};

const calcHeader = o => `${o * 0.04}vw`;
const calcProfile = o => `translateX(-${o * 0.02}vw)`;
const calcSuccess = o => `translateX(${o * 0.01}vw)`;
const calcStripe1 = o => `calc(-80vh + ${Math.exp(o * 0.004)}vh)`;
const calcStripe2 = o => `calc(-10vh + ${Math.exp(o * 0.003)}vh)`;

function Company({data, offset, companyRef}) {
    const classes = useStyles();

    return (
        <div ref={companyRef} className={classes.companyGrid}>
            <animated.div className={classes.stripe1} style={{height: offset.interpolate(calcStripe1)}}/>
            <animated.div className={classes.stripe2} style={{height: offset.interpolate(calcStripe2)}}/>
            <div className={classes.preblock}/>
            <div className={classes.heading}>
                <animated.div className={classes.hleft} style={{marginLeft: offset.interpolate(calcHeader)}}>UNTERNEHMEN</animated.div>
                <div className={classes.hright}/>
            </div>
            <div className={classes.splitter}/>

            <animated.div className={classes.profile} style={{transform: offset.interpolate(calcProfile)}}>
                <div className={classes.title}>Profil</div>
                <div className={classes.cont}>
                    <div>{data.profil1}</div>
                    <div className={classes.between}>{data.profil2}</div>
                    <div>{data.profil3}</div>
                </div>
            </animated.div>
            <animated.div className={classes.success} style={{transform: offset.interpolate(calcSuccess)}}>
                <div className={classes.title}>Kundenzufriedenheit</div>
                <div className={classes.cont}>
                    {data.success}
                </div>
            </animated.div>

        </div>
    )
}
Company.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(Company);