import Colors from "../../../../enums/Colors";

const styles = ({

    product: {
        boxSizing: "border-box",
    },

    title: {
        padding: '3vw',
        paddingBottom: '1.5vw',
        fontWeight: "bold",
        color: Colors.ORANGE,
        fontSize: 'calc(16px + 1vw)'
    },

    text: {
        padding: '0 3vw',
        fontSize: 'calc(12px + 0.5vw)',
    },

    text2: {
        fontSize: 'calc(12px + 0.5vw)',
        padding: '0 3vw',
        marginTop: 10
    },

    products: {
        padding: '0 3vw',
        marginTop: 15
    },

    list: {
        fontSize: 'calc(12px + 0.5vw)',
        marginLeft: 40,
        listStyleType: 'square',
        marginBottom: 20
    },

    item: {
        marginTop: 5
    },

    image: {
        height: "auto",
        paddingBottom: 5,
        padding: 0,
        backgroundColor: Colors.ORANGE
    },

    imageInner: {

    }

});

export default styles;