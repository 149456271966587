const Colors = {
  BACKGROUND: '#ffffff',

  // WHITES
  WHITE: '#ffffff',
  WHITE_TRANSPARENT_GHOST: 'rgba(255, 255, 255, 0.05)',
  WHITE_TRANSPARENT_SOFT: 'rgba(255, 255, 255, 0.15)',
  WHITE_TRANSPARENT: 'rgba(255, 255, 255, 0.25)',
  WHITE_TRANSPARENT_HALF: 'rgba(255, 255, 255, 0.5)',

  YELLOW: '#ffd503',
  BLUE: '#1d65c7',
  GREEN: '#3abd13',
  ORANGE: '#ea890b',
  PURPLE: '#7843b5',
  PINK: '#de4592',

  // GREYS
  BLACK: '#000000',
  GREY_1: '#222222',
  GREY_2: '#727272',
  GREY_3: '#969696',
  GREY_4: '#C6C6C6',
  GREY_5: '#EAEAEA',
  GREY_6: '#F2F2F2',
  GREY_7: '#F8F8F8'
};

export default Colors;

export function hexToRGB(hex, alpha) {
  try {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    const a = typeof parseFloat(alpha, 10) === 'number' ? alpha : 1;

    return `rgba(${r}, ${g}, ${b}, ${a})`;
  } catch (e) {
    return hex;
  }
}
