import Colors from "../../enums/Colors";

const styles = ({
    menu: {
        width: '100%',
        height: 64,

        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 9,

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        flexShrink: 0,

        padding: [0],

        backgroundColor: 'rgba(0,0,0,0)',
        transition: 'background-color 0.5s ease-out'
    },

    menuBg: {
        backgroundColor: 'rgba(0,0,0,0.7)'
    },

    leftMenuEntries: {
        position: 'absolute',
        left: '5%',
        height: '100%'
    },

    rightMenu: {
        position: 'absolute',
        right: '5%',
        height: '100%',
    },

    rightMenuSmall: {
        position: 'absolute',
        right: 20,
        height: '100%',
    },

    rightMenuEntries: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    rightMenuEntriesSmall: {
        position: 'absolute',
        left: 100,
        transition: 'left 0.3s ease-out',
        justifyContent: 'flex-end',

        '> .menuItem' : {
            padding: [10, 10]
        }
    },

    open: {
        left: -120
    },

    smallVersion: {
        width: 300,
        height: 'auto',
        flexWrap: 'wrap',
        top: 0,
    },

    menuItemBg: {
        backgroundColor: 'rgba(0,0,0,0.7) !important'
    },

    leftMenuItem: {
        padding: '23px 23px !important'
    },

    menuButton: {
        cursor: 'pointer',
        opacity: 1,
        display: 'flex',
        height: '100%',
        transition: 'opacity 0.2s ease-in',

        '&:hover': {
            opacity: 0.5
        }
    },

    menuButtonImage: {
        paddingLeft: 10,
        paddingRight: 10
    },

    menuItem: {
        color: Colors.WHITE,
        fontSize: 16,
        fontWeight: 'bold',
        padding: [23, 46],
        position: 'relative',
        cursor: 'pointer',
        backgroundColor: 'rgba(0,0,0,0)',
        transition: 'background-color 0.5s ease-out',

        '&:after': {
            height: 2,
            content: "'.'",
            color: 'transparent',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 19,
            margin: [0, 40],
            width: '0%',
            transition: 'width 0.2s ease-in-out'
        },

        '&:hover': {
            '&:after': {
                width: 'calc(100% - 80px)'
            }
        }
    },

    company: {

        '&:after': {
            backgroundColor: Colors.PINK
        }
    },

    products: {

        '&:after': {
            backgroundColor: Colors.ORANGE
        }
    },

    contact: {

        '&:after': {
            backgroundColor: Colors.GREEN
        }
    },

    impressum: {

        '&:after': {
            backgroundColor: Colors.BLUE
        }
    }

});

export default styles;
